//@flow

import React, {
  createContext,
  useContext,
  useMemo,
  PureComponent,
  type ComponentType,
  type Node,
} from 'react';
import PropTypes from 'prop-types';

export const EnvPropTypes = {
  env: PropTypes.object,
};

export type Environment = {|
  testing: boolean,
|};

export type EnvContextType = {
  env: Environment,
};

export const EnvContext = createContext<EnvContextType>((null: any));

type Props = {|
  env: Environment,
  children: Node,
|};

export function EnvProvider({env, children}: Props) {
  const contextValue = useMemo(() => ({env}), [env]);

  return (
    <EnvContext.Provider value={contextValue}>{children}</EnvContext.Provider>
  );
}

type InjectedProps = {|env: Environment|};

export function withEnv<Config>(
  C: ComponentType<{|...Config, ...InjectedProps|}>,
): ComponentType<Config> {
  return class Wrapped extends PureComponent<*> {
    render() {
      return (
        <EnvContext.Consumer>
          {({env}) => <C {...this.props} env={env} />}
        </EnvContext.Consumer>
      );
    }
  };
}

export function useEnv(): EnvContextType {
  return useContext(EnvContext);
}
