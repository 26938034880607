// @flow

// alias material-ui exports
export {
  // createGenerateClassName, -- not in flow-typed
  createMuiTheme,
  // createStyles, -- not in flow-typed
  jssPreset,
  MuiThemeProvider,
  // withStyles, -- overridden below
  withTheme,
  AppBar,
  Avatar,
  Backdrop,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  // Button, -- overridden below
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Collapse,
  CssBaseline,
  // Dialog, -- overridden below
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  // Drawer, -- overridden below
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Fab,
  Fade,
  fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  Grow,
  Hidden,
  Icon,
  // IconButton, -- overridden below
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  MenuList,
  MobileStepper,
  Modal,
  ModalManager,
  NativeSelect,
  Paper,
  Popover,
  Popper,
  Portal,
  Radio,
  RadioGroup,
  // RootRef, -- not in flow-typed
  Select,
  Slide,
  Slider,
  Snackbar,
  SnackbarContent,
  Step,
  StepButton,
  // StepConnector, -- not in flow-typed
  StepContent,
  StepIcon,
  StepLabel,
  Stepper,
  SvgIcon,
  SwipeableDrawer,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
  Tab,
  // TextField, -- overridden below
  Toolbar,
  Tooltip,
  Typography,
  withMobileDialog,
  withWidth,
  Zoom,
} from '@material-ui/core';

// override specific components
export {default as Button} from 'ui/Button';
export {default as Dialog} from 'ui/Dialog';
export {default as Drawer} from 'ui/Drawer';
export {default as IconButton} from 'ui/IconButton';
export {default as TextField} from 'ui/TextField';
export * from 'ui/withStyles';
