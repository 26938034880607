// @flow
import React, {type ElementConfig, PureComponent} from 'react';
import {Button as MUIButton, withStyles} from '@material-ui/core';
import {lighten} from '@material-ui/core/styles/colorManipulator';
import classnames from 'classnames';

import {type ClassesFromFn} from 'types';

const styles = theme => ({
  // extra colors
  warning: {},
  containedWarning: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.error.main, 0.15),
    },
  },
  outlinedWarning: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.error.main, 0.85),
    },
  },
  textWarning: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.error.main, 0.85),
    },
  },

  // fixed widths
  smWidth: {
    minWidth: 128,
  },
  mdWidth: {
    minWidth: 160,
  },
  lgWidth: {
    minWidth: 240,
  },

  // pass-through
  root: {},
  label: {},
  text: {},
  textPrimary: {},
  textSecondary: {},
  outlined: {},
  // outlinedPrimary: {}, -- future mui versions
  // outlinedSecondary: {}, -- future mui versions
  contained: {},
  containedPrimary: {},
  containedSecondary: {},
  focusVisible: {},
  disabled: {},
  colorInherit: {},
  sizeSmall: {},
  sizeLarge: {},
  fullWidth: {},
});

type Variant = 'text' | 'outlined' | 'contained';
type Color = 'default' | 'inherit' | 'primary' | 'secondary' | 'warning';
type Size = 'small' | 'medium' | 'large';
type Width = 'auto' | 'small' | 'medium' | 'large';

type Props = {|
  ...ElementConfig<typeof MUIButton>,
  variant?: Variant,
  color?: Color,
  size?: Size,
  width?: Width,
  classes: ClassesFromFn<typeof styles>,
|};

// can't be a function component since they don't take
// ref's, and next Links pass refs to wrapped <a>'s
class Button extends PureComponent<Props> {
  static defaultProps = {
    width: 'auto',
    variant: 'text',
  };

  render() {
    const {
      variant,
      color,
      size,
      width,
      classes: {
        root: rootClassName,
        warning,
        containedWarning,
        outlinedWarning,
        textWarning,
        smWidth,
        mdWidth,
        lgWidth,
        ...classes
      },
      ...props
    } = this.props;

    return (
      <MUIButton
        {...props}
        variant={variant}
        color={color === 'warning' ? 'default' : color}
        size={size}
        classes={{
          root: classnames(rootClassName, {
            // extra colors
            [warning]: color === 'warning',
            [containedWarning]:
              color === 'warning' &&
              (variant === 'contained' || variant === 'fab'),
            [outlinedWarning]: color === 'warning' && variant === 'outlined',
            [textWarning]: color === 'warning' && variant === 'text',
            // fixed widths
            [smWidth]: width === 'small',
            [mdWidth]: width === 'medium',
            [lgWidth]: width === 'large',
          }),
          ...classes,
        }}
      />
    );
  }
}

export default withStyles(styles)(Button);
