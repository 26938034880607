// @flow
import React, {type ElementConfig, type AbstractComponent} from 'react';
import {Dialog as MUIDialog} from '@material-ui/core';

import {withEnv, type Environment} from 'env';

type Props = {|
  ...ElementConfig<typeof MUIDialog>,
  env: Environment,
|};

function Dialog({env, ...props}: Props) {
  return (
    <MUIDialog {...(env.testing ? {transitionDuration: 0} : {})} {...props} />
  );
}

export default (withEnv(Dialog): AbstractComponent<
  ElementConfig<typeof MUIDialog>,
>);
