// @flow
import React, {type ElementConfig} from 'react';
import {IconButton as MUIIconButton, withStyles} from '@material-ui/core';
import {lighten} from '@material-ui/core/styles/colorManipulator';
import classnames from 'classnames';

import {type ClassesFromFn} from 'types';

const styles = theme => ({
  // extra colors
  warning: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.error.main, 0.85),
    },
  },

  // pass-through
  root: {},
  label: {},
});

type Color = 'default' | 'inherit' | 'primary' | 'secondary' | 'warning';

type Props = {|
  ...ElementConfig<typeof MUIIconButton>,
  color?: Color,
  classes: ClassesFromFn<typeof styles>,
|};

function IconButton({
  color,
  classes: {root: rootClassName, warning, ...classes},
  ...props
}: Props) {
  return (
    <MUIIconButton
      {...props}
      color={color === 'warning' ? 'default' : color}
      classes={{
        root: classnames(rootClassName, {
          // extra colors
          [warning]: color === 'warning',
        }),
        ...classes,
      }}
    />
  );
}

export default withStyles(styles)(IconButton);
