// @flow
import {type AbstractComponent} from 'react';
import {
  makeStyles as muiMakeStyles,
  withStyles as muiWithStyles,
} from '@material-ui/styles';
// material-ui has bad flow support for withStyles, so we're
// improving it here with Classes<Styles>
//
// For now, this will only support the StyleRulesCallback way of
// defining styles:
//
//    const styles = (theme: Theme) => ({
//      myClass: {
//        backgroundColor: theme.palette.primary.main,
//      },
//    });
//
//    type Props = {|
//      classes: Classes<typeof styles>,
//    |};
//

// todo: upgrade to latest flow-typed which has defs for the next 4 things
export type Theme = Object;
type CSSProperties = Object;
type StyleRules = {[string]: CSSProperties};
type StyleRulesCallback = (theme: Theme) => StyleRules;

// gets inferred return type from a function
type ExtractReturnType<F> = $Call<<R>((...args: any[]) => R) => R, F>;

// extract 'classes' prop type from style object or fn passed to withStyles
type ClassesFromObj<Rules: StyleRules> = {|
  +[$Keys<Rules>]: string,
|};

export type Classes<Styles: StyleRulesCallback> = ClassesFromObj<
  ExtractReturnType<Styles>,
>;

// todo: make better HOC typings around this
type InjectedStyle<T> = {|classes: {[$Keys<T>]: string}|};

export const withStyles = muiWithStyles;

export const typedWithStyles = (muiWithStyles: <Config, ThemeObj>(
  (t: Theme) => ThemeObj | ThemeObj,
) => (
  C: AbstractComponent<{|...Config, ...InjectedStyle<ThemeObj>|}>,
) => AbstractComponent<Config>);

export const makeStyles = (muiMakeStyles: <ThemeObj>(
  (Theme) => ThemeObj,
) => () => {[$Keys<ThemeObj>]: string});
