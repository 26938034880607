// @flow
import React, {type ElementConfig, type AbstractComponent} from 'react';
import {TextField as MUITextField} from '@material-ui/core';

import {withEnv, type Environment} from 'env';

type Props = {|
  ...ElementConfig<typeof MUITextField>,

  // injected
  env: Environment,
|};

function TextField({autoFocus, env, ...props}: Props) {
  return (
    <MUITextField autoFocus={env.testing ? false : autoFocus} {...props} />
  );
}

export default (withEnv(TextField): AbstractComponent<
  ElementConfig<typeof MUITextField>,
>);
