// @flow
import React, {type ElementConfig, type AbstractComponent} from 'react';
import {Drawer as MUIDrawer} from '@material-ui/core';

import {withEnv, type Environment} from 'env';

type Props = {|
  ...ElementConfig<typeof MUIDrawer>,
  env: Environment,
|};

function Drawer({env, ...props}: Props) {
  return (
    <MUIDrawer {...(env.testing ? {transitionDuration: 0} : {})} {...props} />
  );
}

export default (withEnv(Drawer): AbstractComponent<
  ElementConfig<typeof MUIDrawer>,
>);
